import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, AreaChart, Area } from 'recharts';
import axios from 'axios';
import { LockClosedIcon } from '@heroicons/react/24/solid';


const stats = [
  { name: 'Annual Return (%)', key: 'ann_ret' },
  { name: 'Sharpe Ratio', key: 'sharpe' },
  { name: 'Number of Stocks', key: 'no_stocks' },
];

export default function ModelPage() {
  const location = useLocation();
  const { model } = location.state || {};
  const [performanceData, setPerformanceData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [factorData, setFactorData] = useState([]);

  const [currentTab, setCurrentTab] = useState('Performance');

  const [holdingsData, setHoldingsData] = useState(null);
  const [holdingsLoading, setHoldingsLoading] = useState(true);

  const tabs = [
    { 
      name: 'Performance', 
      current: currentTab === 'Performance'
    },
    { 
      name: 'Holdings', 
      current: currentTab === 'Holdings',
      locked: holdingsData ? !holdingsData.user_approved : true // Lock until we know they're approved
    }
  ];

  useEffect(() => {
    const fetchModelReturns = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Retrieve the token from local storage
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/models/${model.id}/returns`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header
          },
        });
        setPerformanceData(response.data);
      } catch (error) {
        console.error('Error fetching model returns:', error);
      }
    };

    fetchModelReturns();
  }, [model.id]);

  useEffect(() => {
    const fetchModelSector = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Retrieve the token from local storage
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/models/${model.id}/sector`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header
          },
        });
        setSectorData(response.data);
      } catch (error) {
        console.error('Error fetching model sector data:', error);
      }
    };

    fetchModelSector();
  }, [model.id]);

  useEffect(() => {
    const fetchModelFactor = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/models/${model.id}/factors`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFactorData(response.data);
      } catch (error) {
        console.error('Error fetching model sector data:', error);
      }
    };

    fetchModelFactor();
  }, [model.id]);

  useEffect(() => {
    if (currentTab === 'Holdings') {
      const script = document.createElement('script');
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "22063250",
            formId: "2b48c885-655a-40e1-9d30-0fb8e6894aac",
            target: "#hubspotForm" // Target the placeholder div
          });
        }
      };
      document.body.appendChild(script);

      // Cleanup function to remove the script when the component unmounts or tab changes
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [currentTab]); // Run effect when currentTab changes

  useEffect(() => {
    const fetchHoldings = async () => {
      if (currentTab === 'Holdings') {
        try {
          const token = localStorage.getItem('access_token');
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/models/${model.id}/holdings`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setHoldingsData(response.data);
          console.log(response.data)
        } catch (error) {
          console.error('Error fetching holdings data:', error);
        } finally {
          setHoldingsLoading(false);
        }
      }
    };

    fetchHoldings();
  }, [model.id, currentTab]);

  const downloadTradesCSV = () => {
    // Create CSV content
    const headers = ['Security Name,Action,Weight Change\n'];
    const rows = holdingsData.trades.map(trade => 
      `${trade.name.replace(',', ' ')},${trade.action},${trade.percentage_change}\n`
    );
    const csvContent = headers.concat(rows).join('');
    
    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `trades_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!model) {
    return <div>Loading...</div>;
  }

  // Prepare data for the pie chart
  const pieData = sectorData.map(sector => ({
    name: sector.sector,
    value: sector.ea_portfolio * 100, // Convert to percentage
  }));

  

  const COLORS = ['#A3C1DA', '#F9E79F', '#4A90E2', '#F1C40F', '#003366', '#D4AC0D'];




  return (
    <div className="min-h-screen bg-gray-900">
  <div className="mt-5 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-white">{model.model_name}</h1>
            <p className="mt-2 text-sm text-gray-400">{model.model_description}</p>
          </div>
        </div>

        {/* Tabs Section */}
        <div className="bg-gray-900 py-6 sm:px-6 lg:px-8">
          <nav className="flex border-b border-white/10 py-4">
            <ul className="flex min-w-full flex-none gap-x-6 text-sm font-semibold text-gray-400">
              {tabs.map((tab) => (
                <li key={tab.name}>
                  <button
                    className={`${
                      tab.current ? 'text-indigo-400' : 'text-gray-400 hover:text-gray-300'
                    }`}
                    onClick={() => setCurrentTab(tab.name)}
                  >
                    {tab.name}
                    {tab.locked && (
                      <span className="ml-2 text-gray-400 text-xs" title="Coming Soon">
                        <LockClosedIcon className="h-4 w-4 inline" aria-hidden="true" />
                      </span>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {/* End of Tabs Section */}

        {/* Content based on the selected tab */}
        <div className="mt-6">
          {currentTab === 'Performance' ? (
            // Existing content for Performance tab
            <div>
              <div className="mt-6">
                <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                  {stats.map((stat) => (
                    <div key={stat.name} className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">{stat.name}</dt>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-primary">
                          {stat.key !== 'ann_ret' ? model[stat.key] : `${(model[stat.key] * 100).toFixed(2)}%`}
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Performance vs. Benchmark</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart data={performanceData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <defs>
                      <linearGradient id="portfolioReturn" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="rgba(72, 118, 255, 0.5)" />
                        <stop offset="100%" stopColor="rgba(72, 118, 255, 0)" />
                      </linearGradient>
                      <linearGradient id="benchmarkReturn" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="rgba(255, 215, 0, 0.5)" />
                        <stop offset="100%" stopColor="rgba(255, 215, 0, 0)" />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="year" tick={{ fill: 'white' }} />
                    <YAxis tick={{ fill: 'white' }} tickFormatter={(tick) => `${tick}%`} />
                    <Tooltip
                      contentStyle={{ backgroundColor: '#333', border: 'none' }}
                      labelStyle={{ color: '#fff' }}
                      itemStyle={{ color: '#fff' }}
                      formatter={(value, name) => [`${(value).toFixed(2)}%`, name]}
                    />
                    <Legend />
                    <Area type="monotone" dataKey="ann_ret" stroke="rgba(72, 118, 255, 1)" fill="url(#portfolioReturn)" name="Portfolio Return" />
                    <Area type="monotone" dataKey="bm_return" stroke="rgba(255, 215, 0, 1)" fill="url(#benchmarkReturn)" name="Benchmark Return" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <br />
              <div className="overflow-x-auto">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-800">
                  <div className="m-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead className="bg-gray-800 sticky top-0 z-10">
                        <tr>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Year</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"># of Stocks</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Annual Return (%)</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Benchmark Return (%)</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Excess Return (%)</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Sharpe Ratio</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Information Ratio</th>
                          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Monthly Turnover</th>

                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-700 bg-gray-800">
                        {performanceData.map((model) => (
                          <tr key={model.id} className="cursor-pointer">
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.year}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.no_stocks}</td>
                            <td className="whitespace-normal px-2 py-2 text-sm text-gray-300">{(model.ann_ret).toFixed(2)}%</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.bm_return).toFixed(2)}%</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.excess_ret).toFixed(2)}%</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.sharpe}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.ir}</td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.month_turnover).toFixed(2)}%</td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-2">
                <div className="bg-white shadow-lg rounded-lg p-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Sector Exposures</h2>
                  <div className="border-t border-gray-200 pt-4">
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart >
                        <Pie
                          data={pieData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          innerRadius={60} // Set inner radius to create a doughnut effect
                          label={false}
                        >
                          {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value, name) => [`${value.toFixed(2)}%`, name]} // Include sector name in tooltip
                        />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Factor Exposures</h2>
                  <div className="border-t border-gray-200 pt-4">
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={factorData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="factor" tickFormatter={(factor) => factor.charAt(0).toUpperCase() + factor.slice(1)} />
                        <YAxis />
                        <Tooltip
                          formatter={(value, name) => [`${value.toFixed(2)}`, name]}
                          labelFormatter={(label) => `Factor: ${label.charAt(0).toUpperCase() + label.slice(1)}`}
                        />
                        <Legend />
                        <Bar dataKey="ea_portfolio" fill='#4A90E2' name="Portfolio" />
                        <Bar dataKey="benchmark" fill='#F1C40F' name="Benchmark" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

              </div>
            </div>
          ) : currentTab === 'Holdings' ? (
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              {holdingsLoading ? (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                </div>
              ) : !holdingsData?.user_approved ? (
                <>
                  <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-7xl">
                      Access Portfolio Holdings
                    </h2>
                    <p className="mt-8 text-pretty text-lg font-medium text-gray-400 sm:text-xl/8">
                      {holdingsData?.message}
                    </p>
                  </div>
                  <div id="hubspotForm" className="mt-4" />
                </>
              ) : (
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-white">
                        Current Holdings as of {new Date(holdingsData.portfolio_date).toLocaleDateString()}
                      </h1>
                      {holdingsData.previous_rebalance_date && (
                        <p className="mt-2 text-sm text-gray-400">
                          Previous rebalance date: {new Date(holdingsData.previous_rebalance_date).toLocaleDateString()}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-8 space-y-8">
                    {/* Current Holdings Table */}
                    <div className="bg-gray-800/50 rounded-lg shadow p-4">
                      <h2 className="text-base font-semibold leading-6 text-white mb-4">
                        Current Holdings as of {new Date(holdingsData.portfolio_date).toLocaleDateString()}
                      </h2>
                      <div className="overflow-x-auto">
                        <div className="max-h-[400px] overflow-y-auto">
                          <table className="min-w-full divide-y divide-gray-700">
                            <thead>
                              <tr>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Security Name</th>
                                <th className="px-3 py-3.5 text-right text-sm font-semibold text-white">Weight</th>
                                <th className="px-3 py-3.5 text-right text-sm font-semibold text-white">CUSIP</th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-800">
                              {holdingsData.current_holdings
                                .sort((a, b) => b.weight - a.weight)
                                .map((holding) => (
                                  <tr key={holding.cusip}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{holding.name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-300">
                                      {(holding.weight * 100).toFixed(2)}%
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-300">
                                      {holding.cusip}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {/* Recent Changes Table */}
                    {holdingsData.trades.length > 0 && (
                      <div className="bg-gray-800/50 rounded-lg shadow p-4">
                        <div className="flex justify-between items-center mb-4">
                          <div>
                            <h2 className="text-base font-semibold leading-6 text-white">Recent Changes</h2>
                            <p className="mt-1 text-sm text-gray-400">
                              Changes from previous rebalance date: {new Date(holdingsData.previous_rebalance_date).toLocaleDateString()}
                            </p>
                          </div>
                          <button
                            onClick={downloadTradesCSV}
                            className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                          >
                            Download CSV
                          </button>
                        </div>
                        <div className="overflow-x-auto">
                          <div className="max-h-[400px] overflow-y-auto">
                            <table className="min-w-full divide-y divide-gray-700">
                              <thead>
                                <tr>
                                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Security Name</th>
                                  <th className="px-3 py-3.5 text-center text-sm font-semibold text-white">Action</th>
                                  <th className="px-3 py-3.5 text-right text-sm font-semibold text-white">Weight Change</th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-800">
                                {holdingsData.trades
                                  .sort((a, b) => Math.abs(parseFloat(b.percentage_change)) - Math.abs(parseFloat(a.percentage_change)))
                                  .map((trade) => (
                                    <tr key={trade.cusip}>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.name}</td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-300">
                                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium
                                          ${trade.action === 'Buy' ? 'bg-green-400/10 text-green-400' : 
                                            trade.action === 'Sell' ? 'bg-red-400/10 text-red-400' : 
                                            'bg-gray-400/10 text-gray-400'}`}>
                                          {trade.action}
                                        </span>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-300">
                                        {trade.percentage_change}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <br />
    </div>
    
    
  );
}
