import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function ModelsTableDashboard() {
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/models`)
      .then(response => {
        setModels(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the models!', error);
      });
  }, []);

  const handleRowClick = (model) => {
    navigate(`/model/${model.id}`, { state: { model } }); // Pass the model data in navigation state
  };

  return (
    <div className="px-4 sm:px-6 lg:px-12 py-12 bg-gray-900">
      <div className="mt-8">
 
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="mx-auto max-w-7xl lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Model Performance</h2>
          <br />
            <div className="overflow-x-auto">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-800">
                <div className="m-2"> {/* Margin around the table */}
                  <table className="min-w-full divide-y divide-gray-700">
                    <thead className="bg-gray-800">
                      <tr>
                        <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Model Name</th>
                        <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Description</th>
                        <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Annual Return (%)</th>
                        <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Sharpe Ratio</th>
                        <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Excess Return (%)</th>
                        <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Monthly Turnover (%)</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-700 bg-gray-800">
                      {models.map((model) => (
                        <tr key={model.id} onClick={() => handleRowClick(model)} className="cursor-pointer">
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.model_name}</td>
                          <td className="whitespace-normal px-2 py-2 text-sm text-gray-300">{model.model_description}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.ann_ret * 100).toFixed(2)}%</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.sharpe}</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.excess_ret * 100).toFixed(2)}%</td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.month_turnover * 100).toFixed(2)}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}