import React, { useEffect, useState } from 'react';
import { 
  XMarkIcon, ChevronRightIcon, ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon, 
  Cog6ToothIcon, FingerPrintIcon, Bars3Icon, CheckIcon 
} from '@heroicons/react/20/solid';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RadioGroup, Radio, Dialog } from '@headlessui/react';

const features = [
  {
    name: 'Data Collection',
    description: 'We use diverse and high-quality alternative data sources to gain unique insights, including earnings estimates, cross asset, transcripts, news, social media sentiment, web traffic, and more',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Algorithmic Analysis',
    description: 'Advanced algorithms process data to identify market trends and opportunities',
    icon: LockClosedIcon,
  },
  {
    name: 'Portfolio Construction',
    description: 'Our portfolios are diverse in nature and meticulously constructed to balance risk and return based on the latest data-driven insights, aimed at maximizing advisor return while managing risk',
    icon: ArrowPathIcon,
  },
  {
    name: 'Ongoing Optimization',
    description: 'Continuous monitoring and adjustment ensure alignment with market conditions and client objectives',
    icon: FingerPrintIcon,
  },
  {
    name: 'Visuals',
    description: 'Infographic or flowchart of the methodology process',
    icon: Cog6ToothIcon,
  }
];

const navigation = [
  { name: 'Methodology', href: '#methodology' },
  { name: 'About', href: '#about' },
  { name: 'Portfolio Factsheets', href: '#factsheets' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Contact', href: '#contact' },
];

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const tiers = [
  {
    name: 'Basic Plan',
    id: 'tier-basic',
    href: '/register',
    price: { monthly: '$399', annually: '$399' },
    description: 'Access to standard model portfolios, rebalancing and essential data insights.',
    features: ['Access to standard model portfolios', 'Rebalancing', 'Essential data insights'],
    mostPopular: false,
  },
  {
    name: 'Professional Plan',
    id: 'tier-professional',
    href: '/register',
    price: { monthly: '$599', annually: '$599' },
    description: 'Enhanced data analytics and additional portfolio options.',
    features: ['Enhanced data analytics', 'Additional portfolio options', 'Rebalancing', 'Essential data insights'],
    mostPopular: true,
  },
  {
    name: 'Premium Plan',
    id: 'tier-premium',
    href: '/register',
    price: { monthly: '$799', annually: '$799' },
    description: 'Full access to all portfolios, advanced data tools, and dedicated support.',
    features: ['Full access to all portfolios', 'Advanced data tools', 'Dedicated support', 'Rebalancing', 'Essential data insights'],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ModelsTable() {
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/models`)
      .then(response => {
        setModels(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the models!', error);
      });
  }, []);

  const handleRowClick = (id) => {
    navigate('/login');
  };

  return (
    <div className="px-4 sm:px-6 lg:px-12 py-12 bg-gray-900">
  <div className="mt-8">
    <div className="mx-auto max-w-7xl lg:px-8">
      <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Model Performance</h2>
      <br />
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="overflow-x-auto">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-800">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-800">
                <tr>
                  <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Model Name</th>
                  <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Description</th>
                  <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Annual Return (%)</th>
                  <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Sharpe Ratio</th>
                  <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Excess Return (%)</th>
                  <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Monthly Turnover (%)</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700 bg-gray-800">
                {models.map((model) => (
                  <tr key={model.id} onClick={() => handleRowClick(model.id)} className="cursor-pointer">
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.model_name}</td>
                    <td className="whitespace-normal px-2 py-2 text-sm text-gray-300">{model.model_description}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.ann_ret * 100).toFixed(2)}%</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.sharpe}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.excess_ret * 100).toFixed(2)}%</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.month_turnover * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default function LandingPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [frequency, setFrequency] = useState(frequencies[0]);

  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate('/login'); // Navigate to the login page
  };


  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.onload = () => {
      window.hbspt.forms.create({
        portalId: "22063250",
        formId: "2b48c885-655a-40e1-9d30-0fb8e6894aac",
        target: "#hubspotForm" // Target the placeholder div
      });
    };
    document.body.appendChild(script);
    
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <header className="bg-gray-900">
        <nav aria-label="Global" className="mx-auto max-w-7xl flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Extract Alpha Model Portfolios for Advisors</span>
              <img alt="Extract Alpha" src="https://extractalpha.com/wp-content/uploads/2023/02/extractalpha-white.svg?color=indigo&shade=500" className="h-8 w-auto" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button type="button" onClick={() => setMobileMenuOpen(true)} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400">
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                {item.name}
              </a>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/register" className="text-sm font-semibold leading-6 text-white">Register&nbsp;&nbsp;&nbsp;</a>
            <a href="/login" className="text-sm font-semibold leading-6 text-white">Log in <span aria-hidden="true">&rarr;</span></a>
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Extract Alpha</span>
                <img alt="Extract Alpha" src="https://extractalpha.com/wp-content/uploads/2023/02/extractalpha-white.svg?color=indigo&shade=500" className="h-8 w-auto" />
              </a>
              <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 rounded-md p-2.5 text-gray-400">
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a href="/login" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800">Log in</a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div id="home" className="relative isolate overflow-hidden bg-gray-900">
        <div aria-hidden="true" className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]">
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201ZM600 0h201v201h-201ZM-400 600h201v201h-201ZM200 800h201v201h-201Z" strokeWidth={0} />
          </svg>
          <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%" strokeWidth={0} />
        </div>
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <div className="mt-24 sm:mt-32 lg:mt-16">
              <a href="#" className="inline-flex space-x-6">
                <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">What's new</span>
                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                  <span>Learn More</span>
                  <ChevronRightIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                </span>
              </a>
            </div>
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Access Superior Investment Strategies with Extract Alpha’s New Model Portfolios for Advisors
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">Empower your clients with cutting-edge alternative data insights.</p>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#factsheets" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Explore Our Portfolios
              </a>
            </div>
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img alt="App screenshot" src="/screenshot.png" width={2432} height={1442} className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
            </div>
          </div>
        </div>
      </div>

      <div id="home" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Transform Your Investment Approach</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              At Extract Alpha, we leverage sophisticated alternative data to craft model portfolios that deliver superior results. Designed purely for Registered Investment Advisors and wealth managers, our portfolios are engineered to meet the evolving needs of your clients.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="/register" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="methodology" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our Methodology</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">Our model portfolios are grounded in rigorous research and innovative data analysis. Here's how we create value for our Advisors:</p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-500" />
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div id="about" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">About Extract Alpha</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Extract Alpha is a leader in alternative data solutions for investment professionals. Our team of experts is dedicated to harnessing the power of data to enhance investment strategies and deliver unparalleled results for Registered Investment Advisors and Wealth Managers.
            </p>
            <div className="mt-10 flex flex-col gap-6">
              <div>
                <h3 className="text-2xl font-bold text-white">Our Vision</h3>
                <p className="mt-2 text-lg text-gray-300">To revolutionize investment decision-making for advisors with alternative data for model portfolios.</p>
              </div>
              <div>
                <h3 className="text-2xl font-bold text-white">Our Team</h3>
                <p className="mt-2 text-lg text-gray-300">Meet the experts behind our innovative solutions.</p>
              </div>
              <div>
                <h3 className="text-2xl font-bold text-white">Our History</h3>
                <p className="mt-2 text-lg text-gray-300">Learn about our journey and milestones.</p>
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#contact" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>

      <ModelsTable />

      <div id="factsheets" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Model Portfolio Factsheets</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Explore detailed factsheets for each of our model portfolios. Each factsheet provides comprehensive information about the portfolio’s strategy, performance, and risk profile.
            </p>
            <div className="mt-10 flex flex-col gap-6 overflow-x-auto">
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(1)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha S&P 500 Long Only</h3>
                <p className="mt-2 text-lg text-gray-300">Pure Alpha Model Portfolio. Combination of Momentum /Value, Truebeats(Value) + Peer Momentum. Monthly Rebalance.</p>
                <a href="#" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(2)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha Russell 1000 Long Only</h3>
                <p className="mt-2 text-lg text-gray-300">Pure Alpha Model Portfolio. Combination of Momentum /Value, Truebeats(Value) + Peer Momentum. Monthly Rebalance.</p>
                <a href="#" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(3)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha Top 2000 Long Only</h3>
                <p className="mt-2 text-lg text-gray-300">Pure Alpha Model Portfolio. Combination of Momentum /Value, Truebeats(Value) + Peer Momentum. Monthly Rebalance.</p>
                <a href="#" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(4)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha Russell 1000 Enhanced</h3>
                <p className="mt-2 text-lg text-gray-300">Portfolio based on changing existing constituents.</p>
                <a href="#" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#contact" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Request a Custom Portfolio
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">Flexible Pricing Plans</p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
            We offer various pricing plans to suit the needs of different investment professionals. Unlike TAMPs and other model portfolio providers, we do not charge basis points, keeping costs low for our advisor community.
          </p>
          <div className="mt-16 flex justify-center">
            <fieldset aria-label="Payment frequency">
              <RadioGroup value={frequency} onChange={setFrequency} className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white">
                {frequencies.map((option) => (
                  <Radio key={option.value} value={option} className="cursor-pointer rounded-full px-2.5 py-1 data-[checked]:bg-indigo-500">
                    {option.label}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers.map((tier) => (
              <div key={tier.id} className={classNames(tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10', 'rounded-3xl p-8 xl:p-10')}>
                <div className="flex items-center justify-between gap-x-4">
                  <h3 id={tier.id} className="text-lg font-semibold leading-8 text-white">{tier.name}</h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">Most popular</p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                  <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                </p>
                <a href={tier.href} aria-describedby={tier.id} className={classNames(tier.mostPopular ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500' : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white', 'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2')}>
                  Get Started
                </a>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="contact" className="relative isolate bg-gray-900">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-white">Get in touch</h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">Have questions or need more information? Our team is here to help you. Reach out to us today.</p>
              <div id="hubspotForm" className="mt-10" /> {/* Placeholder for HubSpot form */}
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <img alt="Extract Alpha" src="https://extractalpha.com/wp-content/uploads/2023/02/extractalpha-white.svg?color=indigo&shade=500" className="h-7" />
              <p className="text-sm leading-6 text-gray-300">© 2024 Extract Alpha. All rights reserved.</p>
              <div className="flex space-x-6">
                <a href="#" className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">LinkedIn</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20.447 20.452H17.2v-5.553c0-1.323-.474-2.229-1.66-2.229-0.905 0-1.444 0.606-1.68 1.191-0.087 0.213-0.108 0.512-0.108 0.812v5.779H9.908s0.049-9.39 0-10.355h3.248v1.467c-0.007 0.011-0.018 0.023-0.026 0.034h0.026v-0.034c0.432-0.667 1.204-1.62 2.932-1.62 2.139 0 3.74 1.391 3.74 4.387v6.122zM7.231 7.548c-1.112 0-1.836 0.73-1.836 1.685 0 0.938 0.707 1.684 1.796 1.684h0.022c1.127 0 1.834-0.746 1.834-1.684-0.022-0.955-0.707-1.685-1.816-1.685zM8.668 20.452H5.793V10.097h2.875v10.355zM22.225 0H1.771C0.79 0 0 0.775 0 1.728v20.542C0 23.225 0.79 24 1.771 24h20.451C23.205 24 24 23.225 24 22.271V1.728C24 0.775 23.205 0 22.225 0z" />
                  </svg>
                </a>
                <a href="#" className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">Twitter</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 4.557a9.84 9.84 0 01-2.828.775 4.932 4.932 0 002.165-2.724 9.86 9.86 0 01-3.127 1.196 4.916 4.916 0 00-8.384 4.482c-4.086-.204-7.719-2.164-10.148-5.144a4.822 4.822 0 00-.664 2.473c0 1.708.87 3.216 2.188 4.099a4.904 4.904 0 01-2.229-.616v0.061c0 2.385 1.693 4.374 3.946 4.828a4.935 4.935 0 01-2.224.084 4.923 4.923 0 004.604 3.419A9.868 9.868 0 010 19.54a13.94 13.94 0 007.548 2.209c9.056 0 14.01-7.506 14.01-14.01 0-0.214-0.004-0.428-0.014-0.641A10.025 10.025 0 0024 4.557z" />
                  </svg>
                </a>
                <a href="#" className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">Facebook</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M22.676 0H1.324C0.594 0 0 0.592 0 1.326v21.348C0 23.407 0.594 24 1.324 24h11.5V14.702h-3.16v-3.62h3.16V8.293c0-3.13 1.908-4.829 4.696-4.829 1.339 0 2.494 0.099 2.828 0.143v3.278h-1.942c-1.524 0-1.819 0.725-1.819 1.785v2.34h3.633l-0.474 3.62h-3.159V24h6.205C23.407 24 24 23.407 24 22.674V1.326C24 0.592 23.406 0 22.676 0z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Quick Links</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a href="#home" className="text-sm leading-6 text-gray-300 hover:text-white">Home</a>
                  </li>
                  <li>
                    <a href="#methodology" className="text-sm leading-6 text-gray-300 hover:text-white">Methodology</a>
                  </li>
                  <li>
                    <a href="#about" className="text-sm leading-6 text-gray-300 hover:text-white">About</a>
                  </li>
                  <li>
                    <a href="#factsheets" className="text-sm leading-6 text-gray-300 hover:text-white">Factsheets</a>
                  </li>
                  <li>
                    <a href="#pricing" className="text-sm leading-6 text-gray-300 hover:text-white">Pricing</a>
                  </li>
                  <li>
                    <a href="#contact" className="text-sm leading-6 text-gray-300 hover:text-white">Contact</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Terms of Service</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-gray-400">&copy; 2024 Extract Alpha. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}
